export const HIGHSCORES = [
    {
        "int": [
            {
                "position": 1,
                "score": 5450,
                "nickname": "Dimitri",
                "game_user_pid": "674eef8c5923c"
            },
            {
                "position": 2,
                "score": 5200,
                "nickname": "Darius",
                "game_user_pid": "674eef8c59691"
            },
            {
                "position": 3,
                "score": 5150,
                "nickname": "Ralph",
                "game_user_pid": "674eef8c5a02c"
            },
            {
                "position": 4,
                "score": 5050,
                "nickname": "Burkhard",
                "game_user_pid": "674eef8c59565"
            },
            {
                "position": 5,
                "score": 4900,
                "nickname": "Mathilde",
                "game_user_pid": "674eef8c59bcb"
            },
            {
                "position": 6,
                "score": 4850,
                "nickname": "Ernst",
                "game_user_pid": "674eef8c59eb7"
            },
            {
                "position": 7,
                "score": 4700,
                "nickname": "Sigrun",
                "game_user_pid": "674eef8c59363"
            },
            {
                "position": 8,
                "score": 4500,
                "nickname": "Olga",
                "game_user_pid": "674eef8c599a2"
            },
            {
                "position": 9,
                "score": 4500,
                "nickname": "Emmy",
                "game_user_pid": "674eef8c59cdf"
            },
            {
                "position": 10,
                "score": 4500,
                "nickname": "Gottlieb",
                "game_user_pid": "674eef8c59414"
            },
            {
                "position": 11,
                "score": 4200,
                "nickname": "Danny",
                "game_user_pid": "674eef8c599c0"
            },
            {
                "position": 12,
                "score": 4050,
                "nickname": "Miroslaw",
                "game_user_pid": "674eef8c5a0fe"
            },
            {
                "position": 13,
                "score": 3700,
                "nickname": "Katharina",
                "game_user_pid": "674eef8c5933e"
            },
            {
                "position": 14,
                "score": 3650,
                "nickname": "Helmuth",
                "game_user_pid": "674eef8c59d5d"
            },
            {
                "position": 15,
                "score": 3650,
                "nickname": "Ute",
                "game_user_pid": "674eef8c5a086"
            },
            {
                "position": 16,
                "score": 3600,
                "nickname": "Valentina",
                "game_user_pid": "674eef8c59bac"
            },
            {
                "position": 17,
                "score": 3600,
                "nickname": "Melanie",
                "game_user_pid": "674eef8c593ab"
            },
            {
                "position": 18,
                "score": 3500,
                "nickname": "Sigmund",
                "game_user_pid": "674eef8c59f36"
            },
            {
                "position": 19,
                "score": 3500,
                "nickname": "Willi",
                "game_user_pid": "674eef8c59dbc"
            },
            {
                "position": 20,
                "score": 3500,
                "nickname": "Bärbel",
                "game_user_pid": "674eef8c59655"
            },
            {
                "position": 21,
                "score": 3450,
                "nickname": "Tatjana",
                "game_user_pid": "674eef8c593f1"
            },
            {
                "position": 22,
                "score": 3450,
                "nickname": "Barbara",
                "game_user_pid": "674eef8c592b3"
            },
            {
                "position": 23,
                "score": 3450,
                "nickname": "Samuel",
                "game_user_pid": "674eef8c5a0a4"
            },
            {
                "position": 24,
                "score": 3400,
                "nickname": "Kerstin",
                "game_user_pid": "674eef8c593ce"
            },
            {
                "position": 25,
                "score": 3400,
                "nickname": "Heinz",
                "game_user_pid": "674eef8c59a20"
            },
            {
                "position": 26,
                "score": 3400,
                "nickname": "Henning",
                "game_user_pid": "674eef8c595a0"
            },
            {
                "position": 27,
                "score": 3400,
                "nickname": "Alma",
                "game_user_pid": "674eef8c59d20"
            },
            {
                "position": 28,
                "score": 3300,
                "nickname": "Emanuel",
                "game_user_pid": "674eef8c59d3e"
            },
            {
                "position": 29,
                "score": 3250,
                "nickname": "Theresia",
                "game_user_pid": "674eef8c59b16"
            },
            {
                "position": 30,
                "score": 3200,
                "nickname": "Emmi",
                "game_user_pid": "674eef8c5947c"
            },
            {
                "position": 31,
                "score": 2900,
                "nickname": "Karen",
                "game_user_pid": "674eef8c595be"
            },
            {
                "position": 32,
                "score": 2850,
                "nickname": "Rosmarie",
                "game_user_pid": "674eef8c59906"
            },
            {
                "position": 33,
                "score": 2850,
                "nickname": "Robert",
                "game_user_pid": "674eef8c59c07"
            },
            {
                "position": 34,
                "score": 2800,
                "nickname": "Lore",
                "game_user_pid": "674eef8c5a0e0"
            },
            {
                "position": 35,
                "score": 2750,
                "nickname": "Katarina",
                "game_user_pid": "674eef8c59b8e"
            },
            {
                "position": 36,
                "score": 2700,
                "nickname": "Inge",
                "game_user_pid": "674eef8c59928"
            },
            {
                "position": 37,
                "score": 2650,
                "nickname": "Richard",
                "game_user_pid": "674eef8c592e5"
            },
            {
                "position": 38,
                "score": 2650,
                "nickname": "Charlotte",
                "game_user_pid": "674eef8c5a11c"
            },
            {
                "position": 39,
                "score": 2650,
                "nickname": "Erhard",
                "game_user_pid": "674eef8c59547"
            },
            {
                "position": 40,
                "score": 2600,
                "nickname": "Ana",
                "game_user_pid": "674eef8c5949f"
            },
            {
                "position": 41,
                "score": 2600,
                "nickname": "Katrin",
                "game_user_pid": "674eef8c59dda"
            },
            {
                "position": 42,
                "score": 2600,
                "nickname": "Heinz-Günter",
                "game_user_pid": "674eef8c599de"
            },
            {
                "position": 43,
                "score": 2600,
                "nickname": "Hans Georg",
                "game_user_pid": "674eef8c59faf"
            },
            {
                "position": 44,
                "score": 2550,
                "nickname": "Ivan",
                "game_user_pid": "674eef8c59d7b"
            },
            {
                "position": 45,
                "score": 2500,
                "nickname": "Klemens",
                "game_user_pid": "674eef8c59a5c"
            },
            {
                "position": 46,
                "score": 2500,
                "nickname": "Halina",
                "game_user_pid": "674eef8c59f91"
            },
            {
                "position": 47,
                "score": 2500,
                "nickname": "Toni",
                "game_user_pid": "674eef8c5a13a"
            },
            {
                "position": 48,
                "score": 2500,
                "nickname": "Mark",
                "game_user_pid": "674eef8c59e53"
            },
            {
                "position": 49,
                "score": 2500,
                "nickname": "Ottmar",
                "game_user_pid": "674eef8c59cbc"
            },
            {
                "position": 50,
                "score": 2500,
                "nickname": "Johanna",
                "game_user_pid": "674eef8c59e16"
            },
            {
                "position": 51,
                "score": 2500,
                "nickname": "Walther",
                "game_user_pid": "674eef8c59a3e"
            },
            {
                "position": 52,
                "score": 2450,
                "nickname": "Franco",
                "game_user_pid": "674eef8c59a98"
            },
            {
                "position": 53,
                "score": 2450,
                "nickname": "Grit",
                "game_user_pid": "674eef8c59b52"
            },
            {
                "position": 54,
                "score": 2450,
                "nickname": "Dunja",
                "game_user_pid": "674eef8c59feb"
            },
            {
                "position": 55,
                "score": 2450,
                "nickname": "Lucie",
                "game_user_pid": "674eef8c59d9d"
            },
            {
                "position": 56,
                "score": 2400,
                "nickname": "Luzia",
                "game_user_pid": "674eef8c5945a"
            },
            {
                "position": 57,
                "score": 2350,
                "nickname": "Wally",
                "game_user_pid": "674eef8c5a00e"
            },
            {
                "position": 58,
                "score": 2350,
                "nickname": "Marcus",
                "game_user_pid": "674eef8c59af8"
            },
            {
                "position": 59,
                "score": 2300,
                "nickname": "Hilda",
                "game_user_pid": "674eef8c59a7a"
            },
            {
                "position": 60,
                "score": 2250,
                "nickname": "Hardy",
                "game_user_pid": "674eef8c59436"
            },
            {
                "position": 61,
                "score": 2200,
                "nickname": "David",
                "game_user_pid": "674eef8c59b34"
            },
            {
                "position": 62,
                "score": 1950,
                "nickname": "Konrad",
                "game_user_pid": "674eef8c594ec"
            },
            {
                "position": 63,
                "score": 1900,
                "nickname": "Volo F",
                "game_user_pid": "674eeff778a55"
            },
            {
                "position": 64,
                "score": 1850,
                "nickname": "Karin",
                "game_user_pid": "674eef8c5a0c2"
            },
            {
                "position": 65,
                "score": 1800,
                "nickname": "Jana",
                "game_user_pid": "674eef8c59c80"
            },
            {
                "position": 66,
                "score": 1750,
                "nickname": "Juergen",
                "game_user_pid": "674eef8c59316"
            },
            {
                "position": 67,
                "score": 1750,
                "nickname": "Marc",
                "game_user_pid": "674eef8c59c43"
            },
            {
                "position": 68,
                "score": 1750,
                "nickname": "Dennis",
                "game_user_pid": "674eef8c59c61"
            },
            {
                "position": 69,
                "score": 1750,
                "nickname": "Inna",
                "game_user_pid": "674eef8c59e72"
            },
            {
                "position": 70,
                "score": 1650,
                "nickname": "Henrik",
                "game_user_pid": "674eef8c5a068"
            },
            {
                "position": 71,
                "score": 1600,
                "nickname": "Wiebke",
                "game_user_pid": "674eef8c59be9"
            },
            {
                "position": 72,
                "score": 1600,
                "nickname": "Gerlinde",
                "game_user_pid": "674eef8c59f55"
            },
            {
                "position": 73,
                "score": 1600,
                "nickname": "Viktoria",
                "game_user_pid": "674eef8c59a01"
            },
            {
                "position": 74,
                "score": 1550,
                "nickname": "Ernestine",
                "game_user_pid": "674eef8c59f73"
            },
            {
                "position": 75,
                "score": 1550,
                "nickname": "Veit",
                "game_user_pid": "674eef8c59ad9"
            },
            {
                "position": 76,
                "score": 1550,
                "nickname": "Margot",
                "game_user_pid": "674eef8c59619"
            },
            {
                "position": 77,
                "score": 1500,
                "nickname": "William",
                "game_user_pid": "674eef8c595dd"
            },
            {
                "position": 78,
                "score": 1500,
                "nickname": "Sandro",
                "game_user_pid": "674eef8c5a04a"
            },
            {
                "position": 79,
                "score": 1450,
                "nickname": "Frida",
                "game_user_pid": "674eef8c59529"
            },
            {
                "position": 80,
                "score": 1400,
                "nickname": "Alwine",
                "game_user_pid": "674eef8c5950b"
            },
            {
                "position": 81,
                "score": 1000,
                "nickname": "Esther",
                "game_user_pid": "674eef8c595fb"
            },
            {
                "position": 82,
                "score": 1000,
                "nickname": "Heinz-Dieter",
                "game_user_pid": "674eef8c59df8"
            },
            {
                "position": 83,
                "score": 1000,
                "nickname": "Maximilian",
                "game_user_pid": "674eef8c59ef4"
            },
            {
                "position": 84,
                "score": 950,
                "nickname": "Günther",
                "game_user_pid": "674eef8c59d02"
            },
            {
                "position": 85,
                "score": 950,
                "nickname": "Volo F",
                "game_user_pid": "674efb6b1af9e"
            },
            {
                "position": 86,
                "score": 950,
                "nickname": "Annelie",
                "game_user_pid": "674eef8c59b70"
            },
            {
                "position": 87,
                "score": 950,
                "nickname": null,
                "game_user_pid": "675026e79cab7"
            },
            {
                "position": 88,
                "score": 950,
                "nickname": "Volo F",
                "game_user_pid": "675885957412d"
            },
            {
                "position": 89,
                "score": 900,
                "nickname": "Heinz-Joachim",
                "game_user_pid": "674eef8c59673"
            },
            {
                "position": 90,
                "score": 900,
                "nickname": "Test2",
                "game_user_pid": null
            },
            {
                "position": 91,
                "score": 900,
                "nickname": null,
                "game_user_pid": "675ccc8e23156"
            },
            {
                "position": 92,
                "score": 900,
                "nickname": "Engelbert",
                "game_user_pid": "674eef8c59947"
            },
            {
                "position": 93,
                "score": 850,
                "nickname": "Joseph",
                "game_user_pid": "674eef8c59ed6"
            },
            {
                "position": 94,
                "score": 850,
                "nickname": null,
                "game_user_pid": "675aea5b50fbd"
            },
            {
                "position": 95,
                "score": 850,
                "nickname": "Anita",
                "game_user_pid": "674eef8c59e35"
            },
            {
                "position": 96,
                "score": 850,
                "nickname": "Sören",
                "game_user_pid": "674eef8c59c25"
            },
            {
                "position": 97,
                "score": 850,
                "nickname": "Osman",
                "game_user_pid": "674eef8c59637"
            },
            {
                "position": 98,
                "score": 850,
                "nickname": "Carola",
                "game_user_pid": "674eef8c59582"
            },
            {
                "position": 99,
                "score": 850,
                "nickname": "Käte",
                "game_user_pid": "674eef8c594cd"
            },
            {
                "position": 100,
                "score": 850,
                "nickname": "Lilly",
                "game_user_pid": "674eef8c59e94"
            },
            {
                "position": 101,
                "score": 850,
                "nickname": "Heinz-Peter",
                "game_user_pid": "674eef8c59983"
            },
            {
                "position": 102,
                "score": 750,
                "nickname": "Ulf",
                "game_user_pid": "674eef8c59fcd"
            },
            {
                "position": 103,
                "score": 700,
                "nickname": "Ilka",
                "game_user_pid": "674eef8c59ab6"
            },
            {
                "position": 104,
                "score": 700,
                "nickname": "Daniel",
                "game_user_pid": "674eef8c59387"
            }
        ],
        "de": [
            {
                "position": 1,
                "score": 5650,
                "nickname": "Joseph",
                "game_user_pid": "674eef8c59ed6"
            },
            {
                "position": 2,
                "score": 5350,
                "nickname": "Ernestine",
                "game_user_pid": "674eef8c59f73"
            },
            {
                "position": 3,
                "score": 5350,
                "nickname": "Helge",
                "game_user_pid": "674eef8c59965"
            },
            {
                "position": 4,
                "score": 5150,
                "nickname": "Sandro",
                "game_user_pid": "674eef8c5a04a"
            },
            {
                "position": 5,
                "score": 4900,
                "nickname": "Käte",
                "game_user_pid": "674eef8c594cd"
            },
            {
                "position": 6,
                "score": 4650,
                "nickname": "Robert",
                "game_user_pid": "674eef8c59c07"
            },
            {
                "position": 7,
                "score": 4450,
                "nickname": "Ana",
                "game_user_pid": "674eef8c5949f"
            },
            {
                "position": 8,
                "score": 4350,
                "nickname": "Walther",
                "game_user_pid": "674eef8c59a3e"
            },
            {
                "position": 9,
                "score": 4300,
                "nickname": "Franco",
                "game_user_pid": "674eef8c59a98"
            },
            {
                "position": 10,
                "score": 4300,
                "nickname": "Sören",
                "game_user_pid": "674eef8c59c25"
            },
            {
                "position": 11,
                "score": 4300,
                "nickname": "Willi",
                "game_user_pid": "674eef8c59dbc"
            },
            {
                "position": 12,
                "score": 4300,
                "nickname": "Hans Georg",
                "game_user_pid": "674eef8c59faf"
            },
            {
                "position": 13,
                "score": 4250,
                "nickname": "Hilda",
                "game_user_pid": "674eef8c59a7a"
            },
            {
                "position": 14,
                "score": 4150,
                "nickname": "Ilka",
                "game_user_pid": "674eef8c59ab6"
            },
            {
                "position": 15,
                "score": 3850,
                "nickname": "Margot",
                "game_user_pid": "674eef8c59619"
            },
            {
                "position": 16,
                "score": 3700,
                "nickname": "Klemens",
                "game_user_pid": "674eef8c59a5c"
            },
            {
                "position": 17,
                "score": 3700,
                "nickname": "Valentina",
                "game_user_pid": "674eef8c59bac"
            },
            {
                "position": 18,
                "score": 3650,
                "nickname": "Karen",
                "game_user_pid": "674eef8c595be"
            },
            {
                "position": 19,
                "score": 3650,
                "nickname": "Daniel",
                "game_user_pid": "674eef8c59387"
            },
            {
                "position": 20,
                "score": 3600,
                "nickname": "Esther",
                "game_user_pid": "674eef8c595fb"
            },
            {
                "position": 21,
                "score": 3550,
                "nickname": "Inna",
                "game_user_pid": "674eef8c59e72"
            },
            {
                "position": 22,
                "score": 3300,
                "nickname": "Jana",
                "game_user_pid": "674eef8c59c80"
            },
            {
                "position": 23,
                "score": 3300,
                "nickname": "Emmi",
                "game_user_pid": "674eef8c5947c"
            },
            {
                "position": 24,
                "score": 3150,
                "nickname": "Marcus",
                "game_user_pid": "674eef8c59af8"
            },
            {
                "position": 25,
                "score": 2800,
                "nickname": "Anita",
                "game_user_pid": "674eef8c59e35"
            },
            {
                "position": 26,
                "score": 2750,
                "nickname": "Lucie",
                "game_user_pid": "674eef8c59d9d"
            },
            {
                "position": 27,
                "score": 2750,
                "nickname": "Charlotte",
                "game_user_pid": "674eef8c5a11c"
            },
            {
                "position": 28,
                "score": 2700,
                "nickname": "Günther",
                "game_user_pid": "674eef8c59d02"
            },
            {
                "position": 29,
                "score": 2700,
                "nickname": "Johanna",
                "game_user_pid": "674eef8c59e16"
            },
            {
                "position": 30,
                "score": 2700,
                "nickname": "Konrad",
                "game_user_pid": "674eef8c594ec"
            },
            {
                "position": 31,
                "score": 2650,
                "nickname": "Andrzej",
                "game_user_pid": "674eef8c59f13"
            },
            {
                "position": 32,
                "score": 2650,
                "nickname": "Hardy",
                "game_user_pid": "674eef8c59436"
            },
            {
                "position": 33,
                "score": 2650,
                "nickname": "Inge",
                "game_user_pid": "674eef8c59928"
            },
            {
                "position": 34,
                "score": 2650,
                "nickname": "Luzia",
                "game_user_pid": "674eef8c5945a"
            },
            {
                "position": 35,
                "score": 2650,
                "nickname": "Burkhard",
                "game_user_pid": "674eef8c59565"
            },
            {
                "position": 36,
                "score": 2600,
                "nickname": "Ottmar",
                "game_user_pid": "674eef8c59cbc"
            },
            {
                "position": 37,
                "score": 2550,
                "nickname": "Kerstin",
                "game_user_pid": "674eef8c593ce"
            },
            {
                "position": 38,
                "score": 2550,
                "nickname": "Dunja",
                "game_user_pid": "674eef8c59feb"
            },
            {
                "position": 39,
                "score": 2550,
                "nickname": "Ute",
                "game_user_pid": "674eef8c5a086"
            },
            {
                "position": 40,
                "score": 2500,
                "nickname": "Theresia",
                "game_user_pid": "674eef8c59b16"
            },
            {
                "position": 41,
                "score": 2500,
                "nickname": "Grit",
                "game_user_pid": "674eef8c59b52"
            },
            {
                "position": 42,
                "score": 2500,
                "nickname": "Marc",
                "game_user_pid": "674eef8c59c43"
            },
            {
                "position": 43,
                "score": 2500,
                "nickname": "Emmy",
                "game_user_pid": "674eef8c59cdf"
            },
            {
                "position": 44,
                "score": 2450,
                "nickname": "Samuel",
                "game_user_pid": "674eef8c5a0a4"
            },
            {
                "position": 45,
                "score": 2450,
                "nickname": "Heinz-Günter",
                "game_user_pid": "674eef8c599de"
            },
            {
                "position": 46,
                "score": 2400,
                "nickname": "Juergen",
                "game_user_pid": "674eef8c59316"
            },
            {
                "position": 47,
                "score": 2350,
                "nickname": "Wiebke",
                "game_user_pid": "674eef8c59be9"
            },
            {
                "position": 48,
                "score": 2250,
                "nickname": "Gerlinde",
                "game_user_pid": "674eef8c59f55"
            },
            {
                "position": 49,
                "score": 2250,
                "nickname": "Lilly",
                "game_user_pid": "674eef8c59e94"
            },
            {
                "position": 50,
                "score": 2200,
                "nickname": "Engelbert",
                "game_user_pid": "674eef8c59947"
            },
            {
                "position": 51,
                "score": 2200,
                "nickname": "Melanie",
                "game_user_pid": "674eef8c593ab"
            },
            {
                "position": 52,
                "score": 1950,
                "nickname": "Richard",
                "game_user_pid": "674eef8c592e5"
            },
            {
                "position": 53,
                "score": 1950,
                "nickname": "Halina",
                "game_user_pid": "674eef8c59f91"
            },
            {
                "position": 54,
                "score": 1900,
                "nickname": "David",
                "game_user_pid": "674eef8c59b34"
            },
            {
                "position": 55,
                "score": 1850,
                "nickname": "Emanuel",
                "game_user_pid": "674eef8c59d3e"
            },
            {
                "position": 56,
                "score": 1750,
                "nickname": "Frida",
                "game_user_pid": "674eef8c59529"
            },
            {
                "position": 57,
                "score": 1750,
                "nickname": "Dennis",
                "game_user_pid": "674eef8c59c61"
            },
            {
                "position": 58,
                "score": 1700,
                "nickname": "Alwine",
                "game_user_pid": "674eef8c5950b"
            },
            {
                "position": 59,
                "score": 1700,
                "nickname": "Ralph",
                "game_user_pid": "674eef8c5a02c"
            },
            {
                "position": 60,
                "score": 1700,
                "nickname": "Katharina",
                "game_user_pid": "674eef8c5933e"
            },
            {
                "position": 61,
                "score": 1700,
                "nickname": "Viktoria",
                "game_user_pid": "674eef8c59a01"
            },
            {
                "position": 62,
                "score": 1700,
                "nickname": "Barbara",
                "game_user_pid": "674eef8c592b3"
            },
            {
                "position": 63,
                "score": 1650,
                "nickname": "Sigmund",
                "game_user_pid": "674eef8c59f36"
            },
            {
                "position": 64,
                "score": 1650,
                "nickname": "Wally",
                "game_user_pid": "674eef8c5a00e"
            },
            {
                "position": 65,
                "score": 1650,
                "nickname": "Mark",
                "game_user_pid": "674eef8c59e53"
            },
            {
                "position": 66,
                "score": 1600,
                "nickname": "Karin",
                "game_user_pid": "674eef8c5a0c2"
            },
            {
                "position": 67,
                "score": 1600,
                "nickname": "Erhard",
                "game_user_pid": "674eef8c59547"
            },
            {
                "position": 68,
                "score": 1600,
                "nickname": "Annelie",
                "game_user_pid": "674eef8c59b70"
            },
            {
                "position": 69,
                "score": 1600,
                "nickname": "Heinz",
                "game_user_pid": "674eef8c59a20"
            },
            {
                "position": 70,
                "score": 1600,
                "nickname": "Osman",
                "game_user_pid": "674eef8c59637"
            },
            {
                "position": 71,
                "score": 1600,
                "nickname": "Katrin",
                "game_user_pid": "674eef8c59dda"
            },
            {
                "position": 72,
                "score": 1550,
                "nickname": "Veit",
                "game_user_pid": "674eef8c59ad9"
            },
            {
                "position": 73,
                "score": 1550,
                "nickname": "Ruth",
                "game_user_pid": "674eef8c59c9e"
            },
            {
                "position": 74,
                "score": 1500,
                "nickname": "Ivan",
                "game_user_pid": "674eef8c59d7b"
            },
            {
                "position": 75,
                "score": 1500,
                "nickname": "Toni",
                "game_user_pid": "674eef8c5a13a"
            },
            {
                "position": 76,
                "score": 1500,
                "nickname": "Heinz-Dieter",
                "game_user_pid": "674eef8c59df8"
            },
            {
                "position": 77,
                "score": 1400,
                "nickname": "William",
                "game_user_pid": "674eef8c595dd"
            },
            {
                "position": 78,
                "score": 1000,
                "nickname": "Alma",
                "game_user_pid": "674eef8c59d20"
            },
            {
                "position": 79,
                "score": 1000,
                "nickname": "Volo F",
                "game_user_pid": "675885957412d"
            },
            {
                "position": 80,
                "score": 950,
                "nickname": null,
                "game_user_pid": "675ae912a0b3c"
            },
            {
                "position": 81,
                "score": 950,
                "nickname": "Katarina",
                "game_user_pid": "674eef8c59b8e"
            },
            {
                "position": 82,
                "score": 950,
                "nickname": "Ulf",
                "game_user_pid": "674eef8c59fcd"
            },
            {
                "position": 83,
                "score": 950,
                "nickname": "Henning",
                "game_user_pid": "674eef8c595a0"
            },
            {
                "position": 84,
                "score": 950,
                "nickname": "Maximilian",
                "game_user_pid": "674eef8c59ef4"
            },
            {
                "position": 85,
                "score": 900,
                "nickname": "Olga",
                "game_user_pid": "674eef8c599a2"
            },
            {
                "position": 86,
                "score": 900,
                "nickname": "Sigrun",
                "game_user_pid": "674eef8c59363"
            },
            {
                "position": 87,
                "score": 900,
                "nickname": "Miroslaw",
                "game_user_pid": "674eef8c5a0fe"
            },
            {
                "position": 88,
                "score": 900,
                "nickname": "Henrik",
                "game_user_pid": "674eef8c5a068"
            },
            {
                "position": 89,
                "score": 900,
                "nickname": "Heinz-Peter",
                "game_user_pid": "674eef8c59983"
            },
            {
                "position": 90,
                "score": 900,
                "nickname": "Mathilde",
                "game_user_pid": "674eef8c59bcb"
            },
            {
                "position": 91,
                "score": 850,
                "nickname": "Lore",
                "game_user_pid": "674eef8c5a0e0"
            },
            {
                "position": 92,
                "score": 800,
                "nickname": "Helmuth",
                "game_user_pid": "674eef8c59d5d"
            },
            {
                "position": 93,
                "score": 750,
                "nickname": "Darius",
                "game_user_pid": "674eef8c59691"
            },
            {
                "position": 94,
                "score": 750,
                "nickname": "Rosmarie",
                "game_user_pid": "674eef8c59906"
            },
            {
                "position": 95,
                "score": 700,
                "nickname": "Danny",
                "game_user_pid": "674eef8c599c0"
            },
            {
                "position": 96,
                "score": 700,
                "nickname": "Gottlieb",
                "game_user_pid": "674eef8c59414"
            },
            {
                "position": 97,
                "score": 700,
                "nickname": "Ernst",
                "game_user_pid": "674eef8c59eb7"
            },
            {
                "position": 98,
                "score": 700,
                "nickname": "Heinz-Joachim",
                "game_user_pid": "674eef8c59673"
            },
            {
                "position": 99,
                "score": 500,
                "nickname": null,
                "game_user_pid": "675c9b02e3187"
            }
        ]
    }
]